import React from "react"
import { Link } from "gatsby"
import {RiArrowLeftSLine, RiBugLine, RiSkullLine} from "react-icons/ri"

import Seo from "../components/seo"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout className="not-found-page">
    <Seo title="Page not found"/>
    <div className="wrapper" style={{
      textAlign: "center"
    }}>
      <header>
        <RiSkullLine style={{
          fontSize: "128px",
          // color: "var(--primary-color)"
          color: "#000"
        }}/>
        <h1>Oops, we did not expect that to happen!</h1>
        <p>Have you wondered into the unknown?</p>
      </header>
      <Link to="/" className="button"><RiArrowLeftSLine className="icon -left"/>Back to Home</Link>
      <Link to="/contact" className="button -outline">Report This <RiBugLine className="icon -right"/></Link>
    </div>
  </Layout>
)

export default NotFound